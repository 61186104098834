import { Component, Host, Prop, h, Fragment } from '@stencil/core';

@Component({
  tag: 'x-link',
  styleUrl: 'x-link.sass',
})
export class XLink {
  @Prop() href: string;
  @Prop() underline: 'none' | 'link' | 'hover' | 'always';
  @Prop() color: 'dark' | 'green' | 'red' | 'primary';
  @Prop() target: '_blank' | '_self' | '_parent' | '_top';
  @Prop() external: boolean;

  private renderInner = () => {
    return (
      <Fragment>
        <span>
          <slot></slot>
        </span>
        {this.external && (
          <Fragment>
            &nbsp;&nbsp;<x-icon glyph="external-link" size="8"></x-icon>
          </Fragment>
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <Host>
        {this.href ? (
          <a href={this.href} target={this.target}>
            {this.renderInner()}
          </a>
        ) : (
          <button type="button">{this.renderInner()}</button>
        )}
      </Host>
    );
  }
}
