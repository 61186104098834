x-link
  a,
  button
    color: inherit
  button
    border: none
    background: none

  &[external]
    a, button
      display: flex
      align-items: center

  &[color="dark"]
    a, button
      span
        color: var(--on-surface-dark)
      &:hover
        span
          color: var(--primary)
  &[color="red"]
    a, button
      span
        color: var(--button-static-red)
      &:hover
        span
          color: var(--button-hover-red)
  &[color="green"]
    a, button
      span
        color: var(--on-surface-green)
  &[color="yellow"]
    a, button
      span
        color: var(--on-surface-yellow)
  &[color="grey"]
    a, button
      span
        color: var(--on-primary-dark-50)
  &[color="primary"]
    a, button
      span
        color: var(--primary)

  &[underline="none"]
    a, button
      span
        text-decoration: none
  &[underline="link"]
    a, button
      span
        text-decoration: underline
      &:hover
        span
          text-decoration: none
  &[underline="hover"]
    a, button
      span
        text-decoration: none
      &:hover
        span
          text-decoration: underline
  &[underline="always"]
    a, button
      span
        text-decoration: underline
